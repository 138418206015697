<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>
                    Manage quiz
                  </h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Quiz
                  </div>
                </div>

                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn @click="addNewQuiz()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add quiz
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
            <div class="">
          <v-skeleton-loader
              type="table-thead"
              v-if="isBusy"
          >
          </v-skeleton-loader>

          <v-skeleton-loader
              v-if="isBusy"
              type="table-row-divider@25"
          >
          </v-skeleton-loader>
          <table class="table" v-if="! isBusy">
            <thead>
              <tr class="px-3">
                <th class="wrap-column px-3">Title</th>
                <th class="wrap-column px-3">Program</th>
                <th class="px-3 text-center">Action</th>
              </tr>
            </thead>
            <template>
              <tr v-for="(item, index) in quizes" :key="index">
                <td class="wrap-column px-3">
                  <a @click="openQuickView(item)"
                     class="text-primary font-weight-bolder text-hover-primary mb-1">
                    {{ item.title }}
                  </a>&nbsp;&nbsp; <i class="fas fa-circle" :class="item.is_active?'dot-active':'dot-inactive'"></i>
                </td>
                <td class="wrap-column px-3">
                  <div class="">
                    {{ item.program ? item.program.title:'NA' }}
                  </div>
                </td>
                <td class="px-3 text-center">
                  <template>
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip>
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="viewResult(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-eye"></i>
                            </span>
                            <span class="navi-text">  View result</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="_calculateResult(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-calculator"></i>
                            </span>
                            <span class="navi-text">  Calculate result</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="__editQuiz(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text">  Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="__deleteQuiz(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text">  Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                    </b-dropdown>
                  </template>
                </td>
              </tr>
              <tr v-if="quizes.length == 0">
                <td class="text-center  px-3" colspan="3">
                  <strong>No data available to display.</strong>
                </td>
              </tr>
            </template>
          </table>
          <b-pagination
              v-if="quizes.length > 0"
              class="pull-right mt-7"
              @input="getAllQuizes"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              :disabled="isBusy"
              last-number
          ></b-pagination>
        </div>
            <!--        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">

            <div class="row">
              <div class="col-12">
                <v-data-table
                    hide-default-footer
                    :headers="headers"
                    :items="quizes"
                    :single-expand="singleExpand"
                    :expanded.sync="expanded"
                    item-key="name"
                    show-expand
                    class="elevation-1"
                    :items-per-page="perPage"
                >
                  <template v-slot:item.title="{ headers, item }">
                    <a class="menu-link" @click.prevent="__editQuiz(item.id)">
                      {{ item.title }}
                    </a>
                    <i class="fas fa-circle"
                       :class="item.is_active?'dot-active':'dot-inactive'"></i>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-row>
                        <v-col cols="2">
                          Scheduled at: {{ item.schedule_at }}
                        </v-col>
                        <v-col cols="2">
                          Start time: {{ item.start_time }}
                        </v-col>
                        <v-col cols="2">
                          End time: {{ item.end_time }}
                        </v-col>
                        <v-col cols="2">
                          Question order: {{ item.question_order }}
                        </v-col>
                        <v-col cols="2">
                          Answer order: {{ item.answer_order }}
                        </v-col>
                        <v-col cols="2">
                          Time per question: {{ item.time_length }}
                        </v-col>
                        <v-col cols="2">
                          Display per page: {{ item.display_per_page }}
                        </v-col>
                        <v-col cols="2">
                          No. of attempt allowed: {{ item.no_of_attempts_allowed }}
                        </v-col>
                        <v-col cols="2">
                          Enable feedback: <span class="badge"
                                                  :class="item.feedback_enable?'badge-success':'badge-danger'">
                                                                             {{ item.feedback_enable ? 'Yes' : 'No' }}
                                                                          </span>

                        </v-col>
                        <v-col cols="2">
                          Display result : <span class="badge"
                                                 :class="item.display_result?'badge-success':'badge-danger'">
                                                                             {{ item.display_result ? 'Yes' : 'No' }}
                                                                          </span>
                        </v-col>
                        <v-col cols="2">
                          Pausable : <span class="badge"
                                           :class="item.is_pausable?'badge-success':'badge-danger'">
                                                                             {{ item.is_pausable ? 'Yes' : 'No' }}
                                                                </span>
                        </v-col>
                        <v-col cols="2">
                          Active : <span class="badge"
                                         :class="item.is_active?'badge-success':'badge-danger'">
                                                                             {{
                            item.is_active ? 'Active' : 'Inactive'
                          }}
                                                                </span>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                  <template v-slot:item.action="{ headers, item }">
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span></slot>
                      </template>
                      &lt;!&ndash;begin::Navigation&ndash;&gt;
                      <div class="navi navi-hover ">

                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="viewResult(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-eye"></i>
                            </span>
                            <span class="navi-text">  View result</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="_calculateResult(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-calculator"></i>
                            </span>
                            <span class="navi-text">  Calculate result</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="__editQuiz(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text">  Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" @click.prevent="__deleteQuiz(item.id)" class="navi-link"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text">  Delete</span>
                          </a>
                        </b-dropdown-text>

                      </div>
                      &lt;!&ndash;end::Navigation&ndash;&gt;
                    </b-dropdown>
                  </template>
                </v-data-table>

              </div>
            </div>
          </div>
        </div>-->
      </div>
          </div>
      </div>
      <v-dialog v-model="quickViewDialog" width="600px">
        <v-card v-if="quizDetail">
          <v-toolbar dark>
            <v-card-title class="headline">
              {{ quizDetail.title ? quizDetail.title : ''}}
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon  @click="closeQuickViewDialog()">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div class="card-body ">
            <div>
              <div class="row">
                <div class="float-left pl-12 mb-3">
                  <div class="font-weight-regular mb-2">
                    <strong> Scheduled at: </strong>  {{ quizDetail.schedule_at }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>Start time: </strong> {{ quizDetail.start_time ? quizDetail.start_time : 'N/A' }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>End time: </strong> {{ quizDetail.end_time ? quizDetail.end_time : 'N/A' }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong> No. of attempt allowed: </strong> {{ quizDetail.no_of_attempts_allowed ? quizDetail.no_of_attempts_allowed : 'N/A' }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>  Enable feedback: </strong> {{ quizDetail.no_of_attempts_allowed ? quizDetail.no_of_attempts_allowed : 'N/A' }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong> Pausable: </strong>   <span class="badge badge-success text-lg`" v-bind:class="{ 'badge-success': quizDetail.is_pausable, 'badge-danger': !quizDetail.is_pausable }">{{ quizDetail.is_pausable ? 'Yes' : 'No' }}</span>
                  </div>
                </div>
                <div class="float-right  pl-12 mb-3">
                  <div class="font-weight-regular mb-2">
                    <strong>  Question order: </strong>  {{ quizDetail.question_order }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>  Answer order: </strong>  {{ quizDetail.answer_order }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong>  Time per question: </strong>  {{ quizDetail.time_length }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong> Display per page: </strong>  {{ quizDetail.display_per_page }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong> Display result :</strong>  {{ quizDetail.display_per_page }}
                  </div>
                  <div class="font-weight-regular mb-2">
                    <strong> Active :</strong>    <span class="badge badge-success text-lg`" v-bind:class="{ 'badge-success': quizDetail.is_active, 'badge-danger': !quizDetail.is_active }">{{ quizDetail.is_active ? 'Active' : 'Inactive' }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 text-right">
                  <v-btn
                      class="btn btn-standard cancel-btn"
                      depressed
                      @click="closeQuickViewDialog"
                  >Cancel
                  </v-btn>
                  <v-btn @click="__editQuiz(quizDetail.id)"    class="text-white ml-2 btn btn-primary"
                         depressed large>
                    Edit
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-toolbar dark>
            <v-card-title class="headline">
              Add new quiz
              <hr>
            </v-card-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon  @click="closeQuiz">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Quiz title"
                    v-model="quiz.title"
                    outlined
                    dense
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.quiz.title.$error">This information is required</span>
              </v-col>
              <!--                        <v-col cols="6">-->
              <!--                            <v-select-->
              <!--                                    label="Academic Year"-->
              <!--                                    v-model="quiz.academic_year_id"-->
              <!--                                    :items="academic_years"-->
              <!--                                    item-text="title"-->
              <!--                                    item-value="id"-->
              <!--                                    outlined-->
              <!--                                    dense-->
              <!--                            >-->
              <!--                            </v-select>-->
              <!--                        </v-col>-->
              <v-col cols="12">
                <v-autocomplete label="Program"
                                v-model="quiz.program_id"
                                :items="programs"
                                item-text="title"
                                item-value="id"
                                outlined
                                dense>
                </v-autocomplete>
                <span class="text-danger" v-if="$v.quiz.program_id.$error">This information is required</span>
              </v-col>
              <v-col cols="6">
                <v-select
                    label="Type"
                    v-model="quiz.type"
                    :items="types"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field type="number"
                    label="Total number of questions"
                    v-model="quiz.total_number_question"
                    outlined
                    dense
                >
                </v-text-field>

              </v-col>
              <v-col cols="12">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="quiz.schedule_at"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="quiz.schedule_at"
                        label="Scheduled at"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="quiz.schedule_at"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(quiz.schedule_at)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span class="text-danger" v-if="$v.quiz.schedule_at.$error">This information is required</span>
              </v-col>
              <v-col cols="12" class="text-right pull-right float-right">
                <v-btn class="btn btn-standard cancel-btn" depressed @click="closeQuiz">
                  Cancel
                </v-btn>
                <v-btn class="btn btn-primary text-white ml-3" :loading="isBusy" @click="createOrUpdate"
                       depressed>
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import {QuizMixin} from "@/mixins/QuizMixin";
import {required} from "vuelidate/lib/validators";

export default {
  name: "QuestionBanks",
  mixins: [QuizMixin],
  validations: {
    quiz: {
      title: {required},
      program_id: {required},
      total_number_question: {required},
      schedule_at: {required},
    }
  },
  components: {},
  data() {
    return {
      dialog: false,
      isBusy: false,
      menu: false,
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: 'Title',
          align: 'start',
          sortable: false,
          value: 'title',
        },
        {text: 'Program', value: 'program.title'},
        {text: 'Action', value: 'action'},
      ],
      quizes: [],
      total: null,
      perPage: null,
      page: null,
      quiz:{
        total_number_question:1,
      },

      quickViewDialog:false,
      quizDetail:null,
    }
  },
  mounted() {
   this.getAllQuizes();
  },
  methods: {
    getAllQuizes(){
      this.__getQuiz();
    },
    addNewQuiz() {
      this.dialog = true;
    },
    closeQuiz() {
      this.dialog = false;
    },
    __editQuiz(id) {
      this.$router.push({name: 'quiz-update', params: {quiz_id: id}})
    },
    __deleteQuiz(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.deleteQuiz(id);
          }
        }
      });
    },
    createOrUpdate() {

      this.$v.quiz.$touch();
      if (this.$v.quiz.$error) {
        setTimeout(() => {
          this.$v.quiz.$reset();
        }, 3000);
      } else {
        let fd = this.quiz;
        if (this.edit) {
          this.__updateQuiz(fd);
        } else {
          this.__createQuiz(fd);
        }
      }
    },
    _calculateResult(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.calculateResult(id);
          }
        }
      });
    },
    viewResult(id) {
      this.$router.push({name: 'quiz-result', params: {id: id}})
    },
    openQuickView(item){
      this.quickViewDialog=true;
      this.quizDetail=item;
    },
    closeQuickViewDialog(){
      this.quickViewDialog=false;
      this.quizDetail=null;
    }

  }
};
</script>
